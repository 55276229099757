<template>
  <v-container class="py-6">
    <v-row>
      <v-col cols="12">
        <div class="box-wrapper">
          <div
            class="box-overlay"
            :class="{'open': isSidebar}"
            @click="isSidebar = !isSidebar"
          />
          <div
            class="box-sidebar pb-3 shadow-sm"
            :class="{'open': isSidebar}"
          >
            <sidebar />
          </div>
          <div class="box-content">
            <div class="d-flex justify-end pa-2 d-block d-md-none">
              <v-btn
                icon
                @click="isSidebar = !isSidebar"
              >
                <v-icon dark>
                  mdi-format-list-bulleted-square
                </v-icon>
              </v-btn>
            </div>
            <div class="box-container">
              <div class="d-flex justify-space-between flex-wrap mb-5">
                <div class="d-flex align-center ">
                  <v-avatar
                    tile
                    size="25"
                    class="me-3"
                  >
                    <img
                      src="@/assets/images/icons/user_filled.svg"
                      alt=""
                    >
                  </v-avatar>
                  <h2 class="mb-0">
                    Chỉnh sửa thông tin cá nhân
                  </h2>
                </div>
                <v-btn
                  outlined
                  color="primary"
                  class="text-capitalize font-600"
                  @click="$router.push('/ca-nhan')"
                >
                  Quay về trang cá nhân
                </v-btn>
              </div>

              <v-row>
                <v-col cols="12">
                  <base-card>
                    <div class="pa-5">
                      <v-row>
                        <v-col
                          cols="12"
                          lg="6"
                        >
                          <p class="text-14 mb-1">
                            Tên
                          </p>
                          <v-text-field
                            v-model="userInfo.ten"
                            outlined
                            dense
                            hide-details=""
                            class=""
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          lg="6"
                        >
                          <p class="text-14 mb-1">
                            Email
                          </p>
                          <v-text-field
                            v-model="userInfo.email"
                            outlined
                            dense
                            hide-details=""
                            class=""
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          lg="6"
                        >
                          <p class="text-14 mb-1">
                            Phone
                          </p>
                          <v-text-field
                            v-model="userInfo.sodienthoai"
                            outlined
                            dense
                            hide-details=""
                            class=""
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-btn
                            color="primary"
                            class="text-capitalize font-600"
                            @click="CapNhat"
                          >
                            Cập nhật
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </base-card>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import sidebar from '@/components/DashboardSidebar'
  import { mapGetters } from 'vuex'
  import { request } from '@/plugins/helpers/request'
  import api from '@/plugins/helpers/api'
  export default {
    components: {
      sidebar,

    },
    data () {
      return {
        isSidebar: false,
        rules: [
          value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
        ],
        userInfo: {
          ten: '',
          email: '',
          sodienthoai: '',
        },
      }
    },
    computed: {
      ...mapGetters(['UserData']),
    },
    mounted () {
      this.TaiThongTin()
    },
    methods: {
      TaiThongTin () {
        request.get(api.URL + '/khach-hang')
          .then(resp => {
            const json = resp.data
            this.userInfo.email = json.data.email
            this.userInfo.sodienthoai = json.data.sodienthoai
            this.userInfo.ten = json.data.ten
          })
          .catch(err => {
            this.$toast.error(err, { position: 'top-right' })
          })
      },
      CapNhat () {
        request.patch(api.URL + '/khach-hang/cap-nhat', this.userInfo)
          .then(resp => {
            const json = resp.data
            if (json.success === true) {
              const msg = json.msg || 'Đã cập nhật thành công !!!'
              this.$toast.success(msg)
            } else {
              const msg = json.error || 'Có lỗi khi cập nhật thông tin !!!'
              this.$toast.error(msg)
            }
          })
          .catch(err => {
            this.$toast.error(err, { position: 'top-right' })
          })
      },
    },
  }

</script>
